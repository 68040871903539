<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="value"
    backdrop
    no-header
    right
    shadow
    @change="val => $emit('input', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Add New User</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer ref="inviteUserRules" #default="{ handleSubmit }">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">
          <!-- Email -->
          <app-input
            id="user-email"
            v-model="userData.email"
            :label="$t('user.email')"
            rules="required|email"
          />

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400 class="mr-2" type="submit" @click.prevent="validationForm">
              <font-awesome-icon class="mx-50" icon="user-plus" />
              {{ $t('action.add') }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              <font-awesome-icon class="mr-50" icon="ban" />
              {{ $t('action.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  patchOrganizationUserNewInvitationRequest,
  postOrganizationUserRequest,
} from '@/request/globalApi/requests/organizationRequests'

export default {
  name: 'SidebarInviteUser',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    organizationId: {
      type: [String, Number],
      required: true,
      default: '',
    },
  },
  data() {
    return {
      userData: {
        email: '',
        workrole: '',
        status: 'inactive',
        airportsIds: [],
        rightsCodes: [],
      },
    }
  },
  methods: {
    APIPostOrganizationUser() {
      postOrganizationUserRequest(this.organizationId, this.userData).then(response => {
        this.$router.push({
          name: 'user-organization-edit',
          params: { organization_id: this.organizationId, organizationUser_id: response.data.id },
        })
        this.APIInviteOrganizationUser(response.data.id)
        this.resetValidator()
        this.$emit('update:is-invite-user-sidebar-active', false)
      })
    },
    APIInviteOrganizationUser(userId) {
      patchOrganizationUserNewInvitationRequest(
        this.organizationId,
        userId,
        {
          redirectUrl: `${window.location.origin}/registration-user`,
          loginRedirectUrl: `${window.location.origin}/login`,
        },
      )
    },
    validationForm() {
      this.$refs.inviteUserRules.validate().then(success => {
        if (success) this.onSubmit()
      })
    },
    onSubmit() {
      this.APIPostOrganizationUser()
    },
    resetValidator() {
      requestAnimationFrame(() => {
        if (this.$refs.inviteUserRules) {
          this.$refs.inviteUserRules.reset()
        }
      })
    },
  },
}
</script>

<style lang="scss">
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
