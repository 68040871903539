<template>
  <div>
    <validation-observer ref="accountPasswordRules" tag="form" @submit.prevent="validationForm">
      <div class="d-flex">
        <h4 class="mb-1">{{ $t('account.security.title') }}</h4>
      </div>

      <!-- Field: Old password -->
      <b-row>
        <b-col cols="12" md="6">
          <app-input
            id="oldPassword"
            v-model="password.oldPassword"
            :label="$t('account.security.password.old')"
            type="password"
            required
          />
        </b-col>
      </b-row>

      <!-- Fields: New password -->
      <b-row>
        <b-col cols="12" md="6">
          <app-input
            id="newPassword"
            v-model="password.newPassword"
            vid="confirm"
            :label="$t('account.security.password.new')"
            type="password"
            required
          />
        </b-col>
        <b-col cols="12" md="6">
          <app-input
            id="newPassword"
            v-model="password.repeatNewPassword"
            :name="$t('account.security.password.confirm')"
            :label="$t('account.security.password.confirm')"
            type="password"
            hide-errors
            :state="!!password.repeatNewPassword || null"
            rules="required|password:@confirm"
          >
            <template #error="{ errors }">
              <small class="text-danger">{{ errors[0] === 'not match' ? $t('validator.password_not_match').message : errors[0] }}</small>
            </template>
          </app-input>
        </b-col>
      </b-row>

      <app-form-wizard-footer class="mt-3" previous save next @click:previous="$emit('previous-tab')" @click:next="$emit('next-tab')">
        <template #save-btn>
          <font-awesome-icon icon="paper-plane" />
          {{ $t('account.security.send_reinitialization_request') }}
        </template>
      </app-form-wizard-footer>

    </validation-observer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { patchChangePasswordRequest } from '@/request/globalApi/requests/userRequests'
import { extend } from 'vee-validate'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: () => 'not match',
})

export default {
  name: 'Password',
  components: {
    AppFormWizardFooter,
  },
  props: {
    userAccount: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      saveLoader: false,
      password: {
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
      },
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
  methods: {
    validationForm() {
      return this.$refs.accountPasswordRules.validate().then(success => {
        if (success) {
          patchChangePasswordRequest(this.password)
        }
        return false
      })
    },
  },
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
