<template>
  <div class="d-lg-flex flex-lg-row">

    <b-nav
      id="navbarTab"
      :vertical="$store.getters['app/mdAndUp']"
      :tabs="$store.getters['app/smAndDown']"
    >
      <b-nav-item
        v-for="(tab, index) in tabs"
        :key="tab.name"
        href="javascript:void(0)"
        :active="activeTabIndex === index"
      >
        <div
          class="text-nowrap"
          @click="switchTab(tab.component)"
        >
          <b-button :variant="tab.component === activeTab ? 'primary' : ''" class="nav-btn">
            <span>{{ index + 1 }}</span>
          </b-button>
          <span class="step-title">{{ $t(tab.title) }}</span>
        </div>
      </b-nav-item>
    </b-nav>

    <b-card class="d-inline-flex w-100 mb-0 p-2" no-body>
      <keep-alive>
        <component
          :is="activeTab"
          v-if="userAccount"
          ref="tabs"
          :user-account.sync="userAccount"
          @on-profile-picture-uploaded="onProfilePictureUploaded"
          @previous-tab="switchTab(tabs[activeTabIndex -1].component)"
          @next-tab="switchTab($event || tabs[activeTabIndex +1].component)"
        />
      </keep-alive>
    </b-card>

  </div>
</template>

<script>
import { findIndex } from 'lodash'
import { mapState } from 'vuex'

import usersList from '@/views/user/UserList.vue'
import { fetchUserRequest } from '@/request/globalApi/requests/userRequests'
import MainInformation from './tab/MainInformation.vue'
import Password from './tab/Password.vue'
import AddressBook from './tab/AddressBook.vue'
import PaymentMethod from './tab/PaymentMethod.vue'
import Organization from './tab/Organization.vue'

export default {
  name: 'AccountWizard',
  components: {
    Organization,
    MainInformation,
    AddressBook,
    PaymentMethod,
    Password,
    usersList,
  },
  data() {
    return {
      currentTab: null,
      userAccount: null,
      stepIndex: 0,
      saveLoader: false,
      activeTabIndex: 0,
      tabs: [
        {
          title: this.$t('account.main_informations.title'),
          component: 'main-information',
          ability: ['all'],
          btnFooter: ['save', 'save-next'],
        },
        {
          title: this.$t('account.payment_method.title'),
          component: 'PaymentMethod',
          ability: ['all'],
          btnFooter: ['save', 'save-next'],
        },
        {
          title: this.$t('account.security.title'),
          component: 'Password',
          ability: ['all'],
          btnFooter: ['next', 'reinitRequest', 'save', 'save-next'],
        },
        {
          title: this.$t('account.organization.title'),
          component: 'Organization',
          ability: ['all'],
          btnFooter: ['next'],
        },
        {
          title: this.$t('account.address_book.title'),
          component: 'AddressBook',
          ability: ['all'],
          btnFooter: ['save', 'save-next'],
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    '$route.params.tab': {
      handler(tabName) {
        this.activeTab = tabName || 'main-information'
        const activeTabIndex = findIndex(this.tabs, tab => tab.component === this.activeTab)

        if (activeTabIndex !== -1) this.activeTabIndex = activeTabIndex
      },
      immediate: true,
    },
  },
  mounted() {
    fetchUserRequest(this.user.id)
      .then(response => {
        this.userAccount = response.data
      })
  },
  methods: {
    switchTab(componentName) {
      this.$router.push({
        name: 'account',
        params: {
          tab: componentName,
        },
      })
    },
    onProfilePictureUploaded(val) {
      this.saveLoader = !val
    },
    formSubmitted() {
      this.formSuccess = false
    },
  },
}
</script>

<style lang="scss">
#navbarTab {
  .nav-item {
    padding: 8px;

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;

      .nav-btn {
        border: none;
        margin-right: 1rem;
        border-radius: 6px;
        width: 2.71rem;
        height: 2.71rem;
      }
    }

    .step-title {
      font-size: 1rem;
      font-weight: 600;
      color: #b8c2cc !important;
    }
    .nav-link.active .step-title {
      color: rgb(220, 193, 129) !important;
    }
  }
}

[dir] body.dark-layout #navbarTab {
  .nav-item {
    border-color: #3b4253 !important;

    .nav-link {
      .btn-secondary {
        background-color: rgba(220, 193, 129, 0.08) !important;
      }
    }
  }
}
</style>

