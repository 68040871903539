<template>
  <b-sidebar
    id="sidebar-add-payment-method"
    no-header
    :visible="isAddNewPaymentMethodSidebarActive"
    @change="val => $emit('update:is-add-new-payment-method-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('account.payment_method.title') }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <b-form class="p-1">
        <b-overlay class="my-3" :show="paymentMethodLoading" spinner-medium>
          <div ref="paymentMethod" />
          <!-- Default address -->
          <b-form-group class="mt-1" label-for="paymentMethodDefault">
            <b-form-checkbox v-model="paymentMethodDefault" :disabled="!paymentMethods.length">
              {{ $t('payment_method.default') }}
            </b-form-checkbox>
          </b-form-group>
          <!-- FOOTER -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mr-1" @click="hide">
              <font-awesome-icon icon="ban" class="mr-50" />
              {{ $t('action.cancel') }}
            </b-button>
            <b-button :disabled="lockSubmit" @click="onSubmit">
              <font-awesome-icon icon="plus" class="mr-50" />
              {{ $t('action.add') }}
            </b-button>
          </div>
        </b-overlay>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import { mapActions } from 'vuex'

import { postPaymentMethodRequest } from '@/request/globalApi/requests/paymentMethodRequests'
import stripe from '@/libs/stripes'

export default {
  name: 'SidebarAddPaymentMethod',

  props: {
    paymentMethods: {
      type: Array,
      default: () => [],
    },
    isAddNewPaymentMethodSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      paymentMethodLoading: true,
      paymentMethodDefault: false,
      lockSubmit: true,
      stripe: null,
      payment: null,
      intent: null,
      appearance: {
        theme: 'stripe',
      },
      elements: null,
      paymentElement: null,
      paymentEvent: null,
    }
  },
  updated() {
    if (this.isAddNewPaymentMethodSidebarActive) {
      this.APIFetchStripeIntent()
    }
    if (!this.paymentMethodDefault.length) {
      this.paymentMethodDefault = true
    }
  },
  methods: {
    ...mapActions('stripe', ['fetchStripeIntent']),
    APIFetchStripeIntent() {
      this.paymentMethodLoading = true
      stripe.then(response => {
        this.stripe = response
        this.fetchStripeIntent()
          .then(intent => {
            // SET INTENT
            this.intent = intent.data
            this.createAndMountFormElements()
          })
          .catch(() => {
            this.paymentMethodLoading = false
          })
      })
    },
    createAndMountFormElements() {
      this.elements = this.stripe.elements(
        {
          clientSecret: this.intent.client_secret,
          appearance: this.appearance,
          locale: this.$store.state.appConfig.lang,
        },
      )

      // Create and mount the Payment Element
      this.paymentElement = this.elements.create('payment')
      this.paymentElement.mount(this.$refs.paymentMethod)
      this.paymentMethodLoading = false
      this.paymentElement.on('change', event => {
        this.paymentEvent = event
        if (event.complete) {
          this.lockSubmit = false
        }
      })
    },
    onSubmit() {
      this.lockSubmit = true
      const { elements } = this
      this.stripe
        .confirmSetup({
          elements,
          confirmParams: {
            // Return URL where the customer should be redirected after the SetupIntent is confirmed.
            // @todo: ajouter lien direct vers la tab payment-method
            return_url: `${window.location.origin}/account`,
          },
          redirect: 'if_required',
        })
        .then(result => {
          this.lockSubmit = false
          const payload = {
            paymentMethodId: result.setupIntent.payment_method,
            default: this.paymentMethodDefault,
          }
          postPaymentMethodRequest(payload, '').then(() => {
            this.$emit('update:is-add-new-payment-method-sidebar-active', false)
            this.$emit('refetch-data')
          })
        })
        .catch(() => {
          this.lockSubmit = false
        })
    },
  },
}
</script>
