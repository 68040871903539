<template>
  <div>
    <validation-observer ref="mainInformationsAccountRules" tag="form" @submit.prevent="validationForm">
      <!-- Form: Personal Info Form -->
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <h4 class="mb-1">{{ $t('account.main_informations.title') }}</h4>
      </div>

      <b-row class="mb-1">
        <!-- Media AVATAR-->
        <b-col md="4" sm="12">
          <div>
            <label label-for="avatar-file">{{ $t('account.main_informations.avatar.upload') }}</label>
            <div class="text-muted font-small-3">
              <span class="font-weight-bold">{{ $t('dropzone.accepted_format') }}:</span> <span>png, jpeg</span> |
              <span class="font-weight-bold">{{ $t('dropzone.max_file_size') }}:</span> <span>500Ko</span>
            </div>

            <vue-dropzone
              id="profilePicture-file"
              ref="profilePicture"
              :options="dropzoneOptionsAvatar"
              class="d-flex justify-content-center align-content-center"
              @vdropzone-mounted="dropzoneMounted(userAccount.profilePictureFileUrl, 'profilePicture', 'profilePictureUploaded')"
              @vdropzone-removed-file="removeFile(userAccount.profilePictureFileUrl, userAccount.profilePictureFilePath)"
              @vdropzone-file-added="sendingFile($event, userAccount, 'profilePictureId', 'profilePictureUploaded')"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: first name -->
        <b-col cols="12" lg="6" md="6">
          <app-input
            id="account-firstName"
            v-model="userAccount.firstName"
            :label="$t('user.first_name')"
            required
          />
        </b-col>

        <!-- Field: last name -->
        <b-col cols="12" lg="6" md="6">
          <app-input
            id="account-lastName"
            v-model="userAccount.lastName"
            :label="$t('user.last_name')"
            required
          />
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" lg="6" md="6">
          <app-input
            id="account-email"
            v-model="userAccount.email"
            :label="$t('user.email')"
            rules="required|email"
          />
        </b-col>

        <!-- Field: Phone -->
        <b-col cols="12" lg="6" md="6">
          <b-form-group :label="$t('user.mobile_phone')" label-for="account-phone">
            <vue-tel-input
              v-model="userAccount.phone"
              :class="phoneMobileObj && !phoneMobileObj.valid && userAccount.phone ? 'is-invalid' : null"
              :default-country="userAccount.defaultLangCode"
              class="form-control"
              @validate="val => (phoneMobileObj = val)"
            />
            <small v-if="phoneMobileObj && !phoneMobileObj.valid && userAccount.phone" class="text-danger">{{
              $t('account.main_informations.phone.invalid_format')
            }}</small>
          </b-form-group>
        </b-col>

        <!-- Field: Language -->
        <b-col cols="12" lg="6" md="6">
          <enum-select id="langCode" v-model="userAccount.defaultLangCode" :label="$t('common.lang')" enum-class="LangCode" text="text" />
        </b-col>
      </b-row>
      <!-- Header: Head Office -->
      <div class="d-flex">
        <h4 class="mt-1">{{ $t('user.information') }}</h4>
      </div>
      <b-row>
        <!-- Field: Email Contact-->
        <b-col cols="12" lg="6" md="6">
          <app-input
            id="account-emailContact"
            v-model="userAccount.emailContact"
            :label="$t('user.email_contact')"
          />
        </b-col>

        <!-- Field: CIVILITY -->
        <b-col cols="12" lg="6" md="6">
          <enum-select id="sexType" v-model="userAccount.sex" :label="$t('account.main_informations.sex_type')" enum-class="SexType" required />
        </b-col>

        <!-- Field: Fix Phone -->

        <b-col v-if="false" cols="12" lg="6" md="6">
          <b-form-group :label="$t('user.phone')" label-for="account-phone">
            <vue-tel-input
              v-model="userAccount.phoneFix"
              :class="phoneFixObj && !phoneFixObj.valid && userAccount.phoneFix ? 'is-invalid' : null"
              :default-country="userAccount.defaultLang.code"
              class="form-control"
              this-phone-mobile-obj-valid
              @validate="val => (phoneFixObj = val)"
            />
          </b-form-group>
          <small v-if="phoneFixObj && !phoneFixObj.valid && userAccount.phoneFix" class="text-danger">{{
            $t('account.main_informations.phone.invalid_format')
          }}</small>
        </b-col>

        <!--Description-->
        <b-col cols="12">
          <b-form-group :label="$t('user.description')" label-for="birthdate">
            <b-form-textarea v-model="userAccount.description" rows="5" />
          </b-form-group>
        </b-col>
      </b-row>

      <!--ACTIONS-->
      <b-row class="mr-0 p-1 justify-end end-line">
        <!--DELETE-->
        <div>
          <b-button v-b-modal.modal-prevent-closing class="btn-icon" variant="danger">
            <font-awesome-icon class="mr-50" icon="trash" />
            {{ $t('action.delete') }}
          </b-button>
        </div>
      </b-row>
      <!--MODAL-->
      <b-modal
        id="modal-prevent-closing"
        ref="modal-delete"
        :ok-title="$t('action.delete')"
        :title="$t('alert.delete_confirmation_permanently.title')"
        cancel-variant="outline-secondary"
        @hidden="resetModal"
        @ok="handleOk"
        @show="resetModal"
      >
        <div class="py-1">
          {{ msgDelete }}
        </div>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group :invalid-feedback="$t('alert.delete_confirmation_permanently.validate_deletion')" :state="deleteState">
            <b-form-input id="name-input" v-model="deleteInput" :state="deleteState" required />
          </b-form-group>
        </form>
      </b-modal>

      <app-form-wizard-footer class="mt-3" save save-next @click:save-next="nextRedirection = true" />

    </validation-observer>
  </div>
</template>

<script>
import { VBModal } from 'bootstrap-vue'
import { VueTelInput } from 'vue-tel-input'

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import imgUpload from '@/helpers/imgUpload'
import { deleteUserRequest, patchUserRequest } from '@/request/globalApi/requests/userRequests'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'

export default {
  name: 'MainInformation',
  components: {
    VueTelInput,
    vueDropzone: vue2Dropzone,
    AppFormWizardFooter,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [imgUpload],
  props: {
    userAccount: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      nextRedirection: false,
      phoneMobileObj: null,
      phoneFixObj: null,
      profilePictureUploaded: true,
      deleteInput: '',
      deleteState: null,
      dropzoneOptionsAvatar: {
        url: 'https://localhost',
        dictDefaultMessage: this.$t('account.main_informations.avatar.upload'),
        maxFiles: 1,
        thumbnailWidth: 250,
        /* maxFilesize: 0.5, */
        headers: { 'My-Awesome-Header': 'header value' },
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        uploadMultiple: false,
        init() {
          this.hiddenFileInput.removeAttribute('multiple')
          // eslint-disable-next-line func-names
          this.on('addedfile', function () {
            if (this.files.length > 1) {
              this.removeFile(this.files[0])
            }
          })
        },
      },
    }
  },
  computed: {
    userBase() {
      return this._cloneDeep(this.userAccount)
    },
    msgDelete() {
      return `${this.$t('alert.delete_confirmation_permanently.message')} ${this.$t('alert.delete_confirmation_permanently.validate_deletion')}`
    },
  },
  watch: {
    profilePictureUploaded(val) {
      this.$emit('on-profile-picture-uploaded', val)
    },
  },
  methods: {
    validationForm() {
      return this.$refs.mainInformationsAccountRules.validate().then(success => {
        if (this.phoneMobileObj.valid !== undefined && !this.phoneMobileObj.valid) {
          this.toaster(this.$t('account.main_informations.phone.invalid_format'), 'danger', 'AlertTriangleIcon')
          return false
        }
        if (success) {
          this.editUser()
          if (this.nextRedirection) {
            this.$emit('next-tab')
            this.nextRedirection = false
          }
          return true
        }
        this.toaster(this.$t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
        return false
      })
    },
    editUser() {
      if (this.phoneMobileObj.valid !== undefined && !this.phoneMobileObj.valid) {
        this.toaster(this.$t('account.main_informations.phone.invalid_format'), 'danger', 'AlertTriangleIcon')
      } else {
        patchUserRequest(this.userAccount)
      }
    },
    checkFormValidity() {
      let valid = false
      if (this.deleteInput.toLowerCase() === 'delete') {
        valid = true
      }
      this.deleteState = valid
      return valid
    },
    resetModal() {
      this.deleteInput = ''
      this.deleteState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Hide the modal manually
      this.$nextTick(() => {
        deleteUserRequest(this.userAccount.id).then(() => {
          this.$router.push({ name: 'login' })
        })
        this.$refs['modal-delete'].toggle('#toggle-btn')
      })
    },
  },
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
