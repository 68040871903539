import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/user'

// AUTH
// verify location in auth ?
export const patchChangePasswordRequest = requirements => globalApi.patch('api/change-password', requirements)

// USER

export const fetchUsersRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

export const fetchUserRequest = id => globalApi.get(`${END_POINT}/${id}`)

export const postUserRequest = (user, message) => globalApi.post(END_POINT, user, { message })

export const patchUserRequest = (user, message) => globalApi.patch(`${END_POINT}/${user.id}`, user, { message })

export const deleteUserRequest = (id, message) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${id}`, { message }))

// ADDRESS

export const fetchUserAddressesRequest = (queryParams, userId) => globalApi.get(`${END_POINT}/${userId}/address`, { params: queryParams })

export const fetchUserAddressRequest = (userId, id) => globalApi.get(`${END_POINT}/${userId}/address/${id}`)

export const postUserAddressRequest = (userId, address, message) => globalApi.post(`${END_POINT}/${userId}/address`, address, { message })

// WARNING params changes
export const patchUserAddressRequest = (userId, address, message) => globalApi.patch(`${END_POINT}/${userId}/address/${address.address.id}`, address, { message })

export const deleteUserAddressRequest = (userId, id, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${userId}/address/${id}`, { message }),
)
